<template>
  <section class="qc-chart-upload-files" v-loading="loading">
    <div class="container-xs">
      <div class="qc-upload-block">
        <div class="sub-header">
          <h4>FILE UPLOAD</h4>
        </div>
        <div class="qc-upload-files-inputs">
          <el-form>
            <el-row :gutter="40">
              <el-col :span="6">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text"> Case Type </label>
                    <el-select
                      v-model="formData.case_type"
                      placeholder="Select Case Type"
                      filterable
                      clearable
                      disabled
                    >
                      <el-option
                        v-for="(caseType, index) of caseTypes"
                        :key="index"
                        :value="caseType.value"
                        :label="caseType.case_name"
                      ></el-option>
                    </el-select>
                    <p class="err">
                      <FormError errorName="case_type"></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6" v-if="formData.case_type == 'TOXICOLOGY'">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text"> Result Type </label>
                    <el-select
                      v-model="formData.result_type"
                      placeholder="Select Result Type"
                      :filterable="true"
                      :clearable="true"
                      disabled
                    >
                      <el-option
                        v-for="(type, index) of toxicTypes"
                        :key="index"
                        :value="type.value"
                        :label="type.name"
                      ></el-option>
                    </el-select>
                    <p class="err">
                      <FormError errorName="result_type"></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text"> Result date </label>
                    <el-date-picker
                      type="date"
                      v-model="formData.result_date"
                      placeholder="MM/DD/YYYY"
                      format="MM-DD-YYYY"
                      :editable="false"
                      :disabled-date="disabledDate"
                      :default-time="defaultTime"
                    ></el-date-picker>
                    <p class="err">
                      <FormError errorName="result_date"></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text"> Format Type </label>
                    <el-select
                      v-model="formData.format_type"
                      placeholder="Select Format Type"
                      :filterable="true"
                      :clearable="true"
                    >
                      <el-option
                        v-for="(type, index) of formatTypes"
                        :key="index"
                        :value="type.value"
                        :label="type.name"
                      ></el-option>
                    </el-select>
                    <p class="err">
                      <FormError errorName="result_type"></FormError>
                    </p>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>

        <div class="sub-header">
          <h4>RESULT FILE</h4>
        </div>
        <div class="qc-upload-files">
          <div class="qc-upload-button">
            <el-popover
              placement="top-start"
              title=""
              trigger="hover"
              :disabled="formData.format_type ? true : false"
              content="Please select format type"
            >
              <template #reference>
                <el-upload
                  class="upload-files"
                  drag
                  action
                  :on-change="handleUpload"
                  :on-remove="handleRemove"
                  :auto-upload="false"
                  :file-list="files"
                  :accept="getAcceptFileType"
                  :disabled="!formData.format_type"
                  :show-file-list="false"
                  :multiple="false"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload-text-align">
                    <div class="el-upload__text">
                      <img
                        src="@/assets/images/icons/attachments-icon.svg"
                        alt=""
                      />
                      <h3>Add file or Drag & drop file here</h3>
                    </div>
                  </div>
                  <template #tip>
                    <div class="el-upload-notes">
                      <span class="notes"> Note : </span>
                      <p>
                        1. Upload CSV files for Latest Format type<br />
                        2.Upload TXT files for Legacy Format type
                      </p>
                    </div>
                  </template>
                </el-upload>
              </template>
            </el-popover>
            <p class="err"><FormError errorName="result_file"></FormError></p>
          </div>
          <div class="sample-attachment-file-block">
            <div v-for="(file, index) in files" :key="index">
              <ul class="sample-attachment-file">
                <li>
                  <div class="file-name-attachment">
                    <img
                      src="@/assets/images/icons/attachments/attachments_1.svg"
                      alt=""
                    />
                    <p>{{ file.name }}</p>
                    <el-button
                      type="text"
                      @click="remove"
                      class="btn-close"
                      size="sm"
                    >
                      <img
                        class="hover"
                        src="@/assets/images/icons/attachments/attachment-success.svg"
                        alt=""
                      />
                      <img
                        src="@/assets/images/icons/attachments/remove-button.svg"
                        alt=""
                        class="without-hover"
                      />
                    </el-button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="upload-btn-group">
            <el-button class="qc-upload-btn" @click="submitFileUploadDetails">
              UPLOAD
            </el-button>
          </div>
          <p class="err"><FormError errorName="critical_error"></FormError></p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import caseDataHelper from "@/mixins/caseDataHelper";
const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
export default {
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      defaultTime: [new Date(2000, 2, 1, 10, 0, 0)],
      loading: false,
      files: [],
      caseTypes: [],
      formData: {
        result_file: "",
        case_type: "TOXICOLOGY",
        result_type: "CONFIRMATION",
        result_date: "",
        format_type: "",
        only_qc_data_file: true,
      },
      formatTypes: [
        {
          name: "Legacy",
          value: "LEGACY",
        },
        {
          name: "Latest",
          value: "LATEST",
        },
      ],
      fileTypeMap: {
        LATEST: ".csv",
        LEGACY: ".txt",
      },
      toxicTypes: [
        {
          name: "Screening",
          value: "SCREENING",
        },
        {
          name: "Confirmation",
          value: "CONFIRMATION",
        },
      ],
    };
  },
  mixins: [caseDataHelper],
  components: { FormError },
  computed: {
    getAcceptFileType() {
      return this.fileTypeMap[this.formData.format_type];
    },
    ...mapGetters("errors", ["getErrors", "getMessage"]),
    ...mapGetters("qcCharts", ["getQCUploadFileStatus"]),
  },
  mounted() {
    this.getLabCaseTypes();
    this.$store.commit("errors/reset", null);
  },
  methods: {
    remove(index) {
      this.$confirm("Are you sure want to DELETE File. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        await this.files.splice(index, 1);
      });
    },
    getLabCaseTypes() {
      this.caseTypes = this.getLabBasedCaseTypes(caseConstants.CASE_TYPES_DATA);
    },
    async submitFileUploadDetails() {
      this.loading = true;
      if (this.checkValidations()) {
        let data = new FormData();
        data.append("result_file", this.files[0].raw);
        data.append("case_type", this.formData.case_type);
        data.append("result_type", this.formData.result_type);
        data.append("format_type", this.formData.format_type);
        data.append("result_date", this.formData.result_date);
        data.append("only_qc_data_file", this.formData.only_qc_data_file);
        await this.$store.dispatch("qcCharts/saveQCDataFiles", data);
        if (this.getQCUploadFileStatus) {
          this.$notify({
            title: "Success",
            message: "Result file uploaded successfully",
            type: "success",
          });
          this.$router.push({
            path: "/qc-chart/result-files",
          });
        }
      }
      this.loading = false;
    },
    checkValidations() {
      if (this.files && this.files.length) {
        const file = this.files[0];
        if (
          (this.formData.format_type == "LATEST" &&
            file.name &&
            file.name.toLowerCase().indexOf(".csv") != -1) ||
          (this.formData.format_type == "LEGACY" &&
            file.name &&
            file.name.toLowerCase().indexOf(".txt") != -1)
        ) {
          return true;
        } else {
          this.$message.warning("File format is not correct");
          return false;
        }
      } else {
        this.$message.warning("Please upload a File");
        return false;
      }
    },
    handleRemove(file) {
      console.log(file);
      this.files = [];
    },
    handleUpload(file) {
      file.status = "success";
      file.percentage = 100;
      this.files = [];
      this.files.push(file);
    },
  },
};
</script>

<style lang="scss">
.qc-chart-upload-files {
  .qc-upload-block {
    border: 1.2px solid #e7e7f7;
    border-radius: 5px;
    .sub-header {
      padding: 0.5rem 2rem;
      border-bottom: 1.2px solid #e7e7f7;
    }
    .qc-upload-files-inputs {
      padding: 1.5rem 2rem 0;
      .el-input__inner {
        background: #ffffff;
        border: 0.639774px solid rgba(156, 156, 156, 0.5);
        border-radius: 3.83865px;
      }
    }
    .qc-upload-files {
      padding: 0.5rem 2rem;
      .qc-upload-button {
        .el-upload {
          display: block;
          .el-upload-dragger {
            width: 100%;
            height: 100px;
            background: #ffffff;
            border: 1px dashed #bababc;
            border-radius: 5px;
          }
        }
        .el-upload-text-align {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          .el-upload__text {
            display: flex;
            align-items: center;

            h3 {
              color: #333333;
              font-size: 18px;
              padding: 0 10px;
            }
          }
        }
        .el-upload-notes {
          color: #828282;
          font-size: 14px;
          font-weight: 500;
          padding: 10px 5px;
        }
      }
      .sample-attachment-file-block {
        .sample-attachment-file {
          li {
            .file-name-attachment {
              display: grid;
              grid-template-columns: 30px auto 35px;
              align-items: center;
              padding: 0px 10px 7px 0px;
              border: 0.5px solid #e0e0e0;
              border-radius: 5px;
              width: 40%;
              height: 30px;
              margin: 1rem 0;
              img {
                padding: 0 10px;
                height: 15px;
                width: 15px;
              }
              p {
                color: #4f4f4f;
                white-space: nowrap;

                overflow: hidden;
                text-overflow: ellipsis;
              }
              .el-button {
                .without-hover {
                  display: none;
                  transition: all 0.2s ease-in-out;
                }
                .hover {
                  display: block;
                  transition: all 0.2s ease-in-out;
                }
                &:hover {
                  .without-hover {
                    display: block;
                    transition: all 0.2s ease-in-out;
                  }
                  .hover {
                    display: none;
                    transition: all 0.2s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
      .upload-btn-group {
        text-align: end;
        .el-button {
          background: #4bb543;
          // background: #3fc380;
          border-radius: 3.40909px;
          width: 120px;
          min-height: 30px;
          height: 36px;
          border: none;
          color: white;
          &.qc-upload-btn {
            background: #4bb543;
            // background: #3fc380;
          }
        }
      }
    }
  }
}
</style>
